<template>
  <div class="banner">
    <div>
      <img class="banner-img" src="./images/banner.png" alt="banner" />
      <img class="logo" src="./images/logo.png" alt="meeting-logo" />
      <img class="people" src="./images/people.png" alt="people" />
      <div class="text">
        <p class="title">{{ title }}</p>
        <p>{{ text }}</p>
      </div>
    </div>

    <div class="box-nav">
      <div class="nav" v-if="judge">
        <van-tabs type="card" color="#77B1FB" swipeable @click="onClick">
          <van-tab
            class="tab"
            v-for="(x, i) in formItems"
            :key="i"
            :title="x.title"
          >
          </van-tab>
        </van-tabs>
        <div class="checkbox">
          <div class="content" ref="left">
            <div
              class="choice"
              :class="this.service.readonly ? '' : 'choice-field'"
            >
              <div class="from" v-if="service.readonly">
                <div
                  class="checkbox-group"
                  v-for="(item, index) in equipments[this.service.id].content"
                  :key="index"
                >
                  <div class="title">
                    <van-image
                      width="20"
                      height="20"
                      :src="
                        require(`./images/icon/icon-${
                          item.id <= 7 ? item.id : item.id - 7
                        }.png`)
                      "
                    />
                    <span>{{ item.menu }}</span>
                  </div>
                  <van-checkbox-group
                    v-model="result[index]"
                    direction="horizontal"
                  >
                    <van-checkbox
                      v-for="data in item.childMenu"
                      :name="data.id"
                      :label="data.id"
                      :key="data.menu"
                      shape="square"
                      icon-size="16px"
                      checked-color="##6CA7F3"
                      @click="handleChange(item, data)"
                    >
                      {{ data.menu }}
                    </van-checkbox>
                  </van-checkbox-group>
                </div>
              </div>
              <div class="field" v-else>
                <van-field
                  v-model="textarea"
                  type="textarea"
                  rows="6"
                  autosize
                  :placeholder="placeholder"
                />
              </div>
            </div>
          </div>
          <div class="button">
            <van-button type="info" color="#77B1FB" size="large" @click="button"
              >提交表单</van-button
            >
          </div>
        </div>
      </div>
      <div class="success" v-else>
        <div class="img">
          <img :src="require('./images/icon/icon-chahua.png')" alt="" />
          <van-tag plain size="medium" color="#77B1FB">提交成功！</van-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import { FaultReport } from "@/api/fault";
import { GetAccident } from "@/api/fault";

document.title = 'MeetingIoT'
export default {
  name: "Fault",
  components: {
    // Checkbox,
  },
  data() {
    return {
      title: "请选择遇到的问题",
      text: "Select question type",
      textarea: "", //输入的建议内容
      result: [], //默认勾选状态
      deviceId: [], //需要提交的数据
      roomId: this.$route.query.roomId, //获取当前房间id
      placeholder: "请输入您宝贵的建议....",
      service: {
        id: 0,
        readonly: true,
      }, //默认页面内容显示
      judge: true, //默认不显示成功页
      formItems: [
        {
          title: "软件故障",
          readonly: true, //控制是否为复选框
          id: 0,
        },
        {
          title: "硬件故障",
          readonly: true,
          id: 1,
        },
        {
          title: "建议",
          readonly: false,
          id: 2,
        },
      ],
      equipments: [
        //存放数据
        //默认数据内容
        {
          typeId: 0,
          content: [
            {
              id: 1,
              menu: "灯光软件故障",
              childMenu: [
                {
                  id: "1-1",
                  menu: "未能正常开关",
                },
                {
                  id: "1-2",
                  menu: "未能正常调节亮度",
                },
                {
                  id: "1-3",
                  menu: "部分无法控制",
                },
              ],
            },
            {
              id: 2,
              menu: "空调软件故障",
              childMenu: [
                {
                  id: "2-1",
                  menu: "未能正常开关",
                },
                {
                  id: "2-2",
                  menu: "未能正常调节温度",
                },
                {
                  id: "2-3",
                  menu: "未能正常调节模式",
                },
                {
                  id: "2-4",
                  menu: "未能正常调节风速",
                },
              ],
            },
            {
              id: 3,
              menu: "投影软件故障",
              childMenu: [
                {
                  id: "3-1",
                  menu: "未能正常开关",
                },
                {
                  id: "3-2",
                  menu: "未能正常升价投影",
                },
                {
                  id: "3-3",
                  menu: "未能正常升价幕布",
                },
              ],
            },
            {
              id: 4,
              menu: "电视软件故障",
              childMenu: [
                {
                  id: "4-1",
                  menu: "未能正常开关",
                },
                {
                  id: "4-2",
                  menu: "未能切换信号源",
                },
              ],
            },
            {
              id: 5,
              menu: "麦克风及扬声器软件故障",
              childMenu: [
                {
                  id: "5-1",
                  menu: "扬声器未能正常开关",
                },
                {
                  id: "5-2",
                  menu: "扬声器未能正常调节",
                },
                {
                  id: "5-3",
                  menu: "麦克风未能切换状态",
                },
              ],
            },
            {
              id: 6,
              menu: "感应器软件故障",
              childMenu: [
                {
                  id: "6-1",
                  menu: "未能正常显示状态",
                },
              ],
            },
            {
              id: 7,
              menu: "窗帘软件故障",
              childMenu: [
                {
                  id: "7-1",
                  menu: "未能正常升降",
                },
              ],
            },
          ],
        },
        {
          typeId: 1,
          content: [
            {
              id: 8,
              menu: "灯光硬件故障",
              childMenu: [
                {
                  id: "8-1",
                  menu: "物理损坏",
                },
                {
                  id: "8-2",
                  menu: "线路损坏",
                },
                {
                  id: "8-3",
                  menu: "缺少设备",
                },
                {
                  id: "8-4",
                  menu: "未供电",
                },
              ],
            },
            {
              id: 9,
              menu: "空调硬件故障",
              childMenu: [
                {
                  id: "9-1",
                  menu: "物理损坏",
                },
                {
                  id: "9-2",
                  menu: "线路损坏",
                },
                {
                  id: "9-3",
                  menu: "工作异常",
                },
                {
                  id: "9-4",
                  menu: "未供电",
                },
              ],
            },
            {
              id: 10,
              menu: "投影硬件故障",
              childMenu: [
                {
                  id: "10-1",
                  menu: "硬件脱落",
                },
                {
                  id: "10-2",
                  menu: "线路损坏",
                },
                {
                  id: "10-3",
                  menu: "镜头损坏",
                },
                {
                  id: "10-4",
                  menu: "未供电",
                },
              ],
            },
            {
              id: 11,
              menu: "电视硬件故障",
              childMenu: [
                {
                  id: "11-1",
                  menu: "屏幕损坏",
                },
                {
                  id: "11-2",
                  menu: "线路损坏",
                },
                {
                  id: "11-3",
                  menu: "设备脱落",
                },
                {
                  id: "11-4",
                  menu: "未供电",
                },
              ],
            },
            {
              id: 12,
              menu: "麦克风及扬声器硬件故障",
              childMenu: [
                {
                  id: "12-1",
                  menu: "麦克风电源未就绪",
                },
                {
                  id: "12-2",
                  menu: "麦克风线路断连",
                },
                {
                  id: "12-3",
                  menu: "扬声器电源未就绪",
                },
                {
                  id: "12-4",
                  menu: "麦克风硬件脱落",
                },
                {
                  id: "12-5",
                  menu: "扬声器脱落",
                },
                {
                  id: "12-6",
                  menu: "扬声器外观损坏",
                },
                {
                  id: "12-7",
                  menu: "未供电",
                },
              ],
            },
            {
              id: 13,
              menu: "感应器硬件故障",
              childMenu: [
                {
                  id: "13-1",
                  menu: "设备脱落",
                },
                {
                  id: "13-2",
                  menu: "线路损坏",
                },
                {
                  id: "13-3",
                  menu: "未供电",
                },
              ],
            },
            {
              id: 14,
              menu: "窗帘硬件故障",
              childMenu: [
                {
                  id: "14-1",
                  menu: "窗帘破损",
                },
                {
                  id: "14-2",
                  menu: "窗帘脱落",
                },
                {
                  id: "14-3",
                  menu: "电机故障",
                },
                {
                  id: "14-4",
                  menu: "线路损坏",
                },
                {
                  id: "14-5",
                  menu: "未供电",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * @description 初始化
     */
    init() {
      this.getAccidentType(); //调用接口，获取列表
      this.$nextTick(() => {
        //定义内容区域超出部分滚动
        this.scroll = new BScroll(this.$refs.left, {
          click: true,
        });
      });
    },
    /**
     * @description 标签id
     */
    onClick(name) {
      this.service.readonly = this.formItems[name].readonly;
      this.service.id = this.formItems[name].id;
      if (this.formItems[name].readonly == true) {
        this.$nextTick(() => {
          //点击标签切换后重新计算内容高度
          //定义内容区域超出部分滚动
          this.scroll = new BScroll(this.$refs.left, {
            click: true,
            startY: 0,
          });
        });
      }
    },
    handleChange(e, f) {
      let obj = {
        typeId: this.equipments[this.service.id].typeId,
        classId: e.id,
        id: f.id,
      };
      this.deviceId.push(obj);
    },
    /**
     * @description 表单提交
     */
    button() {
      if (this.textarea != "" || this.deviceId != "") {
        FaultReport({
          roomId: this.roomId,
          type: "",
          useAndProposalContent: this.textarea,
          detailTypeIdList: this.deviceId,
        }).then((res) => {
          this.judge = false; //更新页面是否提交成功
        });
      } else {
        this.$toast("请至少选择一项");
      }
    },
    /**
     * @description 获取故障类型
     */
    getAccidentType() {
      GetAccident({}).then((res) => {
        this.equipments = res;
        // console.log(res);
      });
    },
  },
};
</script>

<style lang="scss">
.banner {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  height: 100%;
  min-height: 100%;
  max-width: 375px;
  .banner-img {
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 44px;
    left: 18px;
    height: 33px;
  }
  .people {
    position: absolute;
    top: 40px;
    left: 198px;
    height: 149px;
  }
  .text {
    position: absolute;
    top: 98px;
    left: 18px;
    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: #2f4785;
    }
    .title {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
    }
  }
  .box-nav {
    height: calc(100% - 200px);
    .nav {
      height: 100%;
      .van-tabs {
        margin-top: 20px;
        .van-tabs__wrap {
          .van-tabs__nav {
            border: 1px solid;
            // border-image: linear-gradient(
            //     135deg,
            //     rgba(217, 192, 248, 1),
            //     rgba(116, 169, 255, 1)
            //   )
            //   1 1;
            & > div:first-child {
              border-color: #b7b8fa !important;
            }
          }
        }
      }
      .checkbox {
        height: calc(100% - 30px);
        overflow: hidden;
        padding-top: 20px;
        .content {
          overflow: hidden;
          padding: 0 20px;
          height: calc(100% - 80px);
          .choice {
            .from {
              .checkbox-group {
                font-size: 12px;
                padding: 0;
                .title {
                  position: relative;
                  text-align: left;
                  margin-bottom: 20px;
                  .van-image {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                  }
                  span {
                    padding-left: 25px;
                    color: #2f4785;
                    font-weight: 500;
                  }
                }
                .van-checkbox-group {
                  .van-checkbox {
                    position: relative;
                    background-color: #f5f4f4;
                    border-radius: 2px;
                    width: 160px;
                    height: 30px;
                    margin-bottom: 20px;
                    &:nth-child(even) {
                      margin-right: 0 !important;
                    }
                    .van-checkbox__icon {
                      position: absolute;
                      z-index: 1;
                      .van-icon {
                        background: none;
                        border: none;
                      }
                    }
                    .van-checkbox__icon--checked {
                      & > ::after {
                        position: absolute;
                        top: -30px;
                        left: 0px;
                        content: "";
                        width: 20px;
                        height: 50px;
                        transform: rotate(50deg);
                        background-color: #6ca7f3;
                        transition: transform 0.15s ease-in 0.05s;
                      }
                      & > ::before {
                        position: absolute;
                        content: "";
                        background: url("./images/check.png") no-repeat;
                        background-size: 100%;
                        width: 12px;
                        height: 10px;
                        top: -4px;
                        left: 3px;
                        z-index: 1;
                      }
                    }
                    .van-checkbox__icon--checked + .van-checkbox__label {
                      background-color: #e4edfa;
                      border: 1px solid #9cc2f2;
                      color: #2f4785;
                    }
                    .van-checkbox__label {
                      position: absolute;
                      border: 1px solid #f5f4f4;
                      margin-left: 0;
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                      line-height: 28px;
                      word-break: keep-all;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      color: #666666;
                    }
                  }
                }
              }
            }
            .field {
              height: 134px;
              border-radius: 11px;
              .van-cell {
                height: 100%;
                border: 1px solid;
                // border-image: linear-gradient(
                //     135deg,
                //     rgba(217, 192, 248, 1),
                //     rgba(149, 176, 253, 1)
                //   )
                //   1 1;
                border-color: rgb(119, 177, 251);
              }
            }
          }
          .choice-field {
            transform: translateY(0px) !important;
          }
        }
        .button {
          padding: 20px 0;
          button {
            width: 130px;
            height: 30px;
            & > div {
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .success {
      position: relative;
      width: 100%;
      height: 100%;
      .img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      span {
        &::before {
          display: none;
        }
      }
    }
  }
  .van-tab {
    margin-top: -1px;
    span {
      color: #2f4785;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .van-tab--active {
    span {
      color: #ffffff;
    }
  }
}
</style>