import request from '@/utils/request'

let baseURL = '/smart'

//提交设备故障错误信息
export function FaultReport(data) {
    return request({
        baseURL: baseURL,
        url: '/accident/add',
        method: 'post',
        data
    })
}



//请求具体故障类型选项
export function GetAccident(data) {
    return request({
        baseURL: baseURL,
        url: '/accident/getAccidentType',
        method: 'get',
        params: data
    })
}